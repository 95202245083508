import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation()

  return (
    <div class="container">
    <footer class="footer d-flex justify-content-center">
       <div class="row">
          <div class="col-xl-12">
          <h6 class="mb-4 text-center">{t('landing.powered_by')}</h6>
                 <Link
                    to="https://www.gamerji.com/"
                    class="footer-logo mb-4"
                    target="_blank"
                    rel="noopener noreferrer"
                 >
                    <img src="/assets/images/GAMERJI.jpg" alt="GAMERJI" width="120" height="40" />
             </Link>
          </div>
          {/* <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
             <div class="row">
                <div class="col">
                   <div class="">
                      <h4 class="title_secondary mb-4">Explore D11</h4>
                      <address class="">
                         <ul class="footer-nav">
                            <li>
                               <Link to={`/login`} class="footer-link" href="#">Create account</Link>
                            </li>
                            <li>
                               <Link to={`/about`} class="footer-link" href="#">About D11</Link>
                            </li>
                            <li>
                               <Link to={`/contact`} class="footer-link" href="#">Contact Us</Link>
                            </li>
                            <li>
                               <Link to={`/`} class="footer-link" href="#">D11 Wiki</Link>
                            </li>
                            <li>
                               <Link to={`/login`} class="footer-link" href="#">Register</Link>
                            </li>
                            <li>
                               <Link to={`/login`} class="footer-link" href="#">Login</Link>
                            </li>
                            <li>
                               <Link to={`/legality`} class="footer-link" href="#">Privacy Policy</Link>
                            </li>
                            <li>
                               <Link to={`/legality`} class="footer-link" href="#">Terms and Conditions</Link>
                            </li>
                         </ul>
                      </address>
                   </div>
                </div>
                <div class="col">
                   <nav class="">
                      <h4 class="title_secondary mb-4">Connect</h4>
                      <ul class="footer-nav mb-4">
                         <li>
                            <a class="footer-link" href="https://d11.gg/partners/">Partners</a>
                         </li>
                         <li>
                            <Link class="footer-link" href="/contact">Contact Us</Link>
                         </li>
                      </ul>
                   </nav>
                   <h4 class="title_secondary mb-4">Follow D11</h4>
                   <ul class="social-links">
                      <li><Link to={`https://www.facebook.com/d11gaming`} target='_blank' style={{color:"#fff"}}><i class="fa-brands fa-facebook-f"></i></Link></li>
                      <li><Link to={`https://www.tiktok.com/@d11.gg`} target='_blank' style={{color:"#fff"}}><i class="fa-brands fa-tiktok"></i></Link></li>
                      <li><Link to={`https://www.instagram.com/d11.gg/`} target='_blank' style={{color:"#fff"}}><i class="fa-brands fa-instagram"></i></Link></li>
                      <li><Link to={`https://discord.gg/3gBbpyqKeD`} target='_blank' style={{color:"#fff"}}><i class="fa-brands fa-discord"></i></Link></li>
                   </ul>
                </div>
             </div>
          </div> */}
       </div>
    </footer>
 </div>
  )
}

export default Footer